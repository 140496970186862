@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    /* IE10+ specific styles go here */
    .mdc-button {
        color: #222223 !important;
    }
    .mdc-button--outlined {
        color: #fff !important;
        border-color: #fff !important;
    }
    .mdc-button--raised {
        background-color: #b40e45 !important;
        color: #fff !important;
    }
    .mdc-list-item{
        background: transparent !important;
    }
    .mdc-list-item--activated {
        color: #b40e45 !important;
    }
    :not(.mdc-list--non-interactive) > .mdc-list-item:focus::before 
    {
        opacity: 0 !important;
    }
    .nav-item-wrapper {
        a {
            .material-icons {
                margin-top: 5px;
            }
        }
    }
    .mdc-radio__outer-circle {
        border: 2px solid #222223 !important;
    }
    .mdc-radio__inner-circle {
        background: #222223;
        border: 2px solid #222223;
    }
}
﻿.tab-actions {
    padding-bottom: 20px;

    .mdc-tab {
        padding: 0 12px;
        text-transform: none;

        .mdc-tab__content {
            .mdc-tab__text-label {
                height: 1px;
                font-family: $brand-font;
                font-size: $p-size;
                font-weight: 600;
                font-style: normal;
                font-stretch: normal;
                line-height: 0.88;
                padding-bottom: 15px;
                letter-spacing: normal;
                text-align: center;
                color: $primary;
            }
        }

        &.mdc-tab--active {
            .mdc-tab__content {
                .mdc-tab__text-label {
                    font-family: $brand-font;
                    font-weight: 600;
                    color: $accent;
                }
            }
        }

        .mdc-tab-indicator {
            &.mdc-tab-indicator--active {
                .mdc-tab-indicator__content--underline {
                    border-top-width: 4px;
                    border-color: $accent;
                }
            }

            .mdc-tab-indicator__content--underline {
                border-top-width: 1px;
                border-color: $gray-accent;
            }
        }
    }

    .tab-pane {
        display: none;
        font-family: $brand-font;
        font-size: $p-size;
        text-align: center;

        &.tab-pane--active {
            display: block;
        }

        .tab-title {
            font-size: 2.6rem;
            font-family: $brand-font;
            font-weight: 700;
        }

        .quote-buttons {
            width: 100%;
        }

        a {
            color: $primary
        }
    }

    .bundle-dialog {
        .mdc-dialog__container {
            width: 375px;

            .mdc-dialog__surface {
                width: 100%;
            }
        }

        .mdc-dialog__title {
            font-family: $brand-font;
            font-size: 1.2rem;
        }

        .bundle-dialog--list {
            flex-wrap: wrap;

            li {
                width: 100%;

                &::before {
                    opacity: 0 !important;
                }

                &:hover::before {
                    opacity: 0 !important;
                }

                .mdc-list-item__text {
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 60em) {
    .faq-tab-background {
        padding: 0 !important;
    }

    .ifbi-faq-hero-container {
        margin-bottom: 60px;
        height: auto !important;
        overflow: hidden;

        .ifbi-faq-hero-content {
            position: relative !important;
            margin-top: 0px !important;
            padding-top: 0px !important;

            $fade-size: 20px;
            box-shadow: 0 (-0.5 * $fade-size) $fade-size $fade-size white;
        }
    }

    .tab-actions {
        width: 100%;
        height: auto;
        padding: 0px !important;
        background: transparent;

        > .mdc-layout-grid {
            padding: 0;

            .tab-pane {
                padding: 0 20px;

                .f-flex {
                    flex-wrap: wrap;

                    > div {
                        width: 100%;
                    }
                }

                .account-features {
                    grid-gap: 0;
                    padding: 10px;
                }

                .mdc-text-field {
                    width: 100%;
                }

                .mdc-select {
                    width: 100%;
                }

                button {
                    width: 75%;
                }
            }
        }

        .mdc-tab-scroller__scroll-content {
            width: 100%;

            .mdc-tab__text-label {
                width: 100% !important;
            }
        }
    }
}

﻿$primary: #af180e;
$secondary: #135c93;
$accent: #222223;
$accent-disabled: #565656;
$background: #75787b;
$background-accent: #ececea;
$gray-accent: #d9d9d6;
$table-row-even: #f5f5f5;
$table-row-odd: #fafafa;

$footer-primary: #6d6d6d;
$footer-secondary: #5d5d5d;
$footer-border: #808080;

$brand-red: #af180e; 
$brand-blue: #3cb4e5;
$brand-green: #c2d500;
$brand-yellow: #f6dfa4;

$white: white;
$white-tint: #FAFAFA;
$light-grey: #d9d8d6;
$light-mid-grey: #bbb;
$mid-grey: #76777a;
$dark-grey: #323233;
$black: #231f20;

$palette: ("primary":$primary, "secondary":$secondary, "accent": $accent, "faded":$background-accent, "light":$background-accent);

$h1-size: 5.2rem;
$h2-size: 4rem;
$h3-size: 3.2rem;
$h4-size: 2.8rem;
$p-size-lg: 2.4rem;
$p-size: 1.6rem;

$top-margin: 55px;
$top-margin-small: 2.4rem;

$brand-font: 'DIN regular', 'Roboto', Helvetica, arial;
$serif-font: 'NeutrafaceSlabText';

$query-small: "only screen and (max-width : 480px)";

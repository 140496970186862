/*Styles for IFBI-hero-grid*/
.hero-container {
    position: relative;
    margin: 0px auto;
    height: 550px;
    background-color: #222223;
    padding-top: 84px;

    .hero-image {
        position: relative;
        max-height: 550px;
        overflow: hidden;
        background-color: #142729;

        img {
            min-width: 100%;
            min-height: 550px;
            opacity: .3;
            text-align: center;
        }
    }
}

.hero-content {
    position: absolute;
    margin: auto;
    top: 0px;
    right: 0px;
    left: 0px;
    height: 719px;
}

/*Styles for IFBI-hero-grid*/
.hero-innerpage-container {
    position: relative;
    margin: 0px auto;
    height: 200px;
    background-color: #222223;
    margin-top: 84px;

    .hero-innerpage-image {
        position: relative;
        max-height: 200px;
        overflow: hidden;
        background-color: #142729;

        img {
            min-width: 100%;
            min-height: 200px;
            opacity: .3;
            text-align: center;
        }
    }
}

.hero-innerpage-content {
    position: absolute;
    margin: auto;
    top: 135px;
    right: 0px;
    left: 0px;
}
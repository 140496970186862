body{
    margin: 0px;
}
p {
    margin: 0px;
    color: #6e6e6e;
}
ul {
    li {
        color: #6e6e6e;
        a{
            color: #222223;
            text-decoration: none;
        }
    }
}
a {
    color: #222223;
    text-decoration: none !important;
}

.material-icons {
    /* Support for IE */
    font-feature-settings: 'liga';
}

.container{
    max-width: 1200px;
    margin: 0 auto;
}

.thumbnail img {
    width: 100%;
    border-radius: 4px;
}

.section-wrapper {
    padding: 80px 16px;
    span {
        display: block;
    }
    ul{
        span {
            display: inline;
        }
    }
    a{
        text-decoration: underline !important;
    }
}
.nav-item-wrapper{
    position: relative;
}

.mdc-menu-surface--anchor{
    transform: translate(10px, 10px)
}

.pagination {
    padding: 0px;
    display: flex;
    list-style: none;

    .page-item {
        border: 1px solid #eee;
        border-radius: 4px;
        margin-right: 4px;
    }
}
.editMode {
    .hero-innerpage-content,
    .mdc-top-app-bar {
        position: relative;
    }
    .hero-innerpage-content{
        top: 0px;
    }

    .hero-container {
        height: auto !important;
    }

    .hero-content {
        top: 150px;
    }
}

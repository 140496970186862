/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 0px) and (max-device-width : 599px) {
    .sfPublicWrapper {
        padding: 0px !important;
        img {
            max-width: none !important;
        }
    }

    .f-t-18px{
        font-size: 16px !important;
    }

    .mdc-top-app-bar {
        padding: 0px;

        .mdc-top-app-bar__title {
            img {
                width: 170px;
            }
        }
    }

    .section-wrapper {
        padding: 40px 16px;
    }

    .hero-container {
        padding-top: 66px;
    }

    .hero-innerpage-container {
        padding-top: 60px !important;
        margin-top: 0px !important;
        .hero-innerpage-image {
            img {
                max-height: 200px;
            }
        }
    }

    .section-wrapper-mobile {
        padding-top: 80px;
    }

    .mdc-card__media--16-9 {
        img {
            height: auto;
        }
    }

    .hero-innerpage-content {
        top: 75px;
    }

    .mdc-drawer {
        .mdc-list-item--activated {
            color: #b40e45;
        }
    }
    .mobile-seperator {
        border-top: 1px solid #555;
        border-bottom: 1px solid #555;
        padding: 16px 0px;
    }
    .copyright {
        padding: 8px;
    }
}

/*Application top toolbar style*/
.mdc-top-app-bar {
    background: #f1f1f1 !important;
    border-bottom: 4px solid #B40E45;
    padding: 8px;

    a {
        margin-left: 8px;
    }
}

/*Card design fix*/
.mdc-card__media--16-9 {
    max-height: 285px;

    &::before {
        margin: 0px;
    }

    img {
        min-width: 100%;
        height: 285px;
    }
}

.demo-card__secondary {
    padding: 0 1rem 8px;
}

.mdc-typography--body2 {
    min-height: 75px;
    overflow: hidden;
    max-height: 75px;
}

.mdc-card__action-buttons {
    span {
        display: inline-flex;
    }

    a {
        text-decoration: none !important;
    }
}

.mdc-typography--headline3 {
    line-height: 3.425rem !important;
}

.mdc-text-field--outlined {
    width: 100%;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: #222223 !important;
}
.mdc-text-field--focused {
    .mdc-text-field__input:required ~ .mdc-floating-label::after,
    .mdc-text-field__input:required ~ .mdc-notched-outline .mdc-floating-label::after {
        color: #222223 !important;
    }
}

.mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-floating-label,
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: #222223 !important;
}
.mdc-text-field--invalid .mdc-text-field__input:required ~ .mdc-floating-label::after, .mdc-text-field--invalid .mdc-text-field__input:required ~ .mdc-notched-outline .mdc-floating-label::after {
    color: #222223 !important;
}

.mdc-input-override {
    border-radius: 4px;
    padding: 16px;
    border: 1px solid #222223;
    margin: 8px 0;
    color: rgba(0,0,0,.7);
    font-size: 16px;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading, .mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch, .mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing,
.mdc-select--invalid:not(.mdc-select--disabled) .mdc-floating-label {
    border-color: #222223 !important;
    color: #222223 !important;
}

﻿.anchor {
    position: relative;
    top: -100px;
}

.back-to-top {
    position: fixed;
    bottom: 0;
    right: 100px;
}

.content {
    display: none;
}

.content--active {
    display: block !important;
}

.tab-actions {
    .mdc-tab__text-label {
        font-size: 1.2rem !important;
    }
    span{
        display: flex !important;
    }
}
details {
    background: #fff;
    display: flex;
    z-index: 0;
}

details[open] {
    border-radius: 2px;
    border-bottom: 1px solid #e0e0e0;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0px;
        border: none;
    }

    .answer {
        p {
            padding-bottom: 24px;
        }
    }

    summary {
        &:after {
            color: #9e9e9e;
            content: "expand_less";
            font-family: 'Material Icons';
            font-feature-settings: 'liga';
        }
    }

    .titleName {
        margin-top: 0;
        border: none;
        font-weight: 500;
    }
}

summary {
    outline: none;
    cursor: pointer;
    padding: 24px 32px 16px 0;
    position: relative;
    z-index: 3;
    background: white;
    flex: 1 100%;
    flex-flow: row wrap;
    font-size: 18px;

    &::-webkit-details-marker {
        display: none;
    }

    &:after {
        content: "expand_more";
        font-family: 'Material Icons';
        font-feature-settings: 'liga';
        color: #9e9e9e;
        font-size: 1.5em;
        padding: 0;
        text-align: center;
        margin-top: 0;
        position: absolute;
        top: calc(50% - 12px);
        bottom: 0;
        right: 0;
        width: 6%;
    }
}

/*From github.com/stationer/DetailsShim*/

details.details_shim_closed,
details.details_shim_open {
    display: block;
}

details.details_shim_closed > * {
    display: none;
}

details.details_shim_closed {
    > summary {
        display: block;

        &:after {
            display: inline-block;
            content: "expand_more";
            font-family: 'Material Icons';
            font-feature-settings: 'liga';
            padding: 0 0.1em;
            margin-right: 0.4em;
            font-size: 0.9em;
        }
    }
}

details.details_shim_open {
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 24px;

    > summary {
        display: block;
    }

    .titleName {
        font-weight: 500;
    }
}

details.details_shim_open > summary:after {
    display: inline-block;
    content: "expand_less";
    font-family: 'Material Icons';
    font-feature-settings: 'liga';
    padding: 0;
    margin-right: 0.35em;
}

details[open] summary ~ * {
    animation: sweep .3s ease-in-out;
}

@keyframes sweep {
    0% {
        opacity: 0;
        margin-left: 0px;
        margin-top: -85px;
    }

    100% {
        opacity: 1;
        margin-left: 0px;
        margin-top: 0px;
    }
}
